<template>
  <div>
    <v-navigation-drawer
      v-model="drawer"
      :permanent="$vuetify.breakpoint.mdAndUp"
      :mini-variant="$vuetify.breakpoint.mdAndUp ? mini : false"
      app
      class="drawerColor"
    >
      <!--  -->
      <AppAccount :mini="mini" />
      <v-list
        v-for="(link, i) in userData.is_admin ? adminLinks : links"
        :key="i"
        class="drawer-list"
      >
        <!-- main drawer -->
        <v-list-item
          :key="i"
          class="white--text"
          v-if="!mini && !link.hasSubs && link.name !== undefined"
          link
          :to="!link.hasSubs ? link.to : ''"
        >
          <v-list-item-icon>
            <v-icon color="white">{{ link.icon }}</v-icon>
          </v-list-item-icon>
          <v-list-item-title>{{ link.name }}</v-list-item-title>
        </v-list-item>
        <!-- Mini drawer -->
        <v-list-item
          v-if="mini && !link.hasSubs && link.name !== undefined"
          class="text-center"
          :key="i"
          :to="!link.hasSubs ? link.to : ''"
          link
        >
          <v-tooltip right class="mini--link">
            <template v-slot:activator="{ on, attrs }">
              <v-icon v-on="on" v-bind="attrs" color="white">{{
                link.icon
              }}</v-icon>
            </template>
            <span>{{ link.name }}</span>
          </v-tooltip>
        </v-list-item>
        <v-list-group
          :value="false"
          v-if="!mini && link.hasSubs"
          @click.stop="routeTo(link.to)"
        >
          <template v-slot:activator>
            <v-list-item-icon>
              <v-icon color="white">{{ link.icon }}</v-icon>
            </v-list-item-icon>
            <v-list-item-title class="white--text">{{
              link.name
            }}</v-list-item-title>
          </template>

          <v-list-item
            v-for="(sub, idx) in link.subs"
            :key="idx"
            :to="sub.to"
            class="white--text"
            link
          >
            <v-list-item-icon class="ml-10">
              <v-icon color="white">{{ sub.icon }}</v-icon>
            </v-list-item-icon>
            <v-list-item-title class="ml-1">{{ sub.name }}</v-list-item-title>
          </v-list-item>
        </v-list-group>

        <v-list-group
          :value="false"
          v-if="mini && link.hasSubs"
          class="text-center"
          @click.stop="routeTo(link.to)"
        >
          <template v-slot:activator :to="link.hasSubs ? link.to : ''">
            <v-tooltip right class="mini--link">
              <template v-slot:activator="{ on, attrs }">
                <v-icon v-on="on" v-bind="attrs" color="white">{{
                  link.icon
                }}</v-icon>
              </template>
              <span>{{ link.name }}</span>
            </v-tooltip>
          </template>

          <v-list-item
            v-for="(sub, idx) in link.subs"
            :key="idx"
            :to="sub.to"
            link
          >
            <span>
              <v-icon style="color: #ffffff">{{ sub.icon }}</v-icon>
            </span>
          </v-list-item>
        </v-list-group>
      </v-list>
    </v-navigation-drawer>
    <AppNav :drawer="drawer" :changeDrawer="changeDrawer" />
  </div>
</template>
<style>
@import "../styles.css";
</style>
<script>
import AppAccount from "./AppAccount";
import AppNav from "./AppNav";
import AdminLinks from "../../modules/admin/AdminLinks";
export default {
  name: "AppSide",
  components: { AppNav, AppAccount },
  data() {
    return {
      drawer: true,
    };
  },
  computed: {
    userData: function () {
      return this.$store.getters["auth/user"];
    },
    adminLinks: function () {
      return [...AdminLinks];
    },
    mini: {
      get: function () {
        return !this.drawer;
      },
      set: function (value) {
        // console.log('mini setter value', value)
      },
    },

    links: function () {
      const sideLinks = this.$store.getters["Dashboard/links"];
      sideLinks.forEach((l) => {
        l.hasSubs = l.subs && l.subs.length !== 0;
      });
      return [...sideLinks];
    },
  },

  methods: {
    changeDrawer: function () {
      this.drawer = !this.drawer;
    },
    gotoDashboard: function (to) {
      console.log(to);
      this.$router.replace({ path: to });
    },
    routeTo(path) {
      this.$router.push({ path });
    },
  },
  watch: {
    drawer() {
      this.drawer ? (this.mini = false) : (this.mini = true);
      console.log("drawer", this.drawer);
    },
  },
};
</script>
